<template>
  <div>
    <div class="uk-section uk-padding-remove-vertical uk-margin-remove-vertical in-equity-breadcrumb in-equity-breadcrumb-green">
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li><router-link :to="{ name: 'trader.home' }">Home</router-link></li>
              <li><router-link :to="{ name: 'trader.academy.index' }">Academy</router-link></li>
              <li><span>{{ details.sort }}. {{ details.title }}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-container uk-margin-medium-top@m">
      <div class="mobile-toggle-list">
        <a href="#" @click.prevent="toggleList">Show Videos</a>
      </div>
      <h2>Week {{ details.sort }}. {{ details.title }}</h2>
      <div class="vid-main-wrapper clearfix">
        <!-- THE PLAYER -->
        <div class="vid-container">
          <div class="course-video">
            <div class="vimeo-player" v-if="! loading">
              <vimeo-player
                v-if="! current.mega"
                ref="player"
                :video-url="current.video_url"
                player-width="auto"
                player-height="auto"
                :options="{ responsive: true }"
              />

              <mega-player :src="current.video_url" v-if="current.mega" />
            </div>
            <h2 class="uk-margin-small">{{ current.title }}</h2>
            <video-comments :about="current.about" type="academy" :id="current.id" role="trader" v-if="! loading" />
          </div>
        </div>

        <!-- THE PLAYLIST -->
        <div class="vid-list-container" id="vid-list-container">
          <a href="#" class="mobile-list-close" @click.prevent="toggleList">close</a>
          <ol id="vid-list" class="vid-list">
            <li v-for="(video, i) in videos" :key="`video-${i}`">
              <router-link :to="{ query: { play: video.id }}">
                <span class="badge-new" v-if="! video.seen && ! loading">new</span>
                <span class="vid-thumb" v-if="! loading">
                  <video-thumbnail :video="video" style="width: 100px" />
                  </span>
                <div class="desc" v-if="! loading">{{ video.title }}</div>
                <template v-if="loading">
                  <p class="skeleton skeleton-text desc"></p>
                  <p class="skeleton skeleton-text desc"></p>
                </template>
              </router-link>
            </li>
          </ol>
          <router-link :to="{ name: 'trader.academy.exam' }" class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" v-if="! loading && details.is_last_week">Proceed</router-link>
          <router-link :to="{ name: 'trader.academy.index' }" class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" v-if="completed_videos && ! loading && ! details.is_last_week">Proceed</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      videos: [{}, {}, {}, {}, {}],
      current: {},
      details: {},
      loading: true,
      completed_videos: true
    }
  },

  methods: {
    toggleList() {
      document.getElementById('vid-list-container').classList.toggle('open')
    },

    fetch() {
      let url = this.play ? `/api/v1/trader/academy/${this.$route.params.id}/load?play=${this.play}` : `/api/v1/trader/academy/${this.$route.params.id}/load`
      this.completed_videos = true
      this.loading = true
      this.videos = [{}, {}, {}, {}, {}]
      document.getElementById('vid-list-container').classList.remove('open')

      this.$axios.get(url).then(response => {
        if(this.play == undefined) {
          this.$router.push({ query: { play: response.data.current.id }})
        }

        this.videos = response.data.videos
        this.current = response.data.current
        this.details = response.data.details
        this.loading = false

        this.videos.forEach(video => {
          if(! video.seen) {
            this.completed_videos = false
          }
        })
      })
    }
  },

  computed: {
    play() {
      return this.$route.query.play
    }
  },

  watch: {
    play() {
      this.fetch()
    }
  }
};
</script>

<style lang="scss" scoped>
.vimeo-player {
  background: #000;
  min-height: 400px;

  @media(max-width: 768px) {
    min-height: auto;
  }
}

.badge-new {
  font-size: 10px;
  position: absolute;
  right: 0;
  color: #ed1c24;
  padding: 1px 3px;
  border: solid 1px #ed1c24;
  border-radius: 20%;
  top: 0;
}

.title {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
}

.caption {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
  padding: 20px 0;
}

.vid-main-wrapper {
  width: 100%;
  min-width: 440px;
  background: #fff;
}

/*  VIDEO PLAYER CONTAINER ############################### */
.vid-container {
  position: relative;
  padding-top: 0;
  width: 70%;
  float: left;

  @media(max-width: 768px) {
    max-height: 100vh;
    overflow-y: auto;
    width: 80%;
    display: block;
  }
}

.vid-container iframe,
.vid-container object,
.vid-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 360px;
}

/*  VIDEOS PLAYLIST ############################### */
.vid-list-container {
  width: calc(30% - 20px);
  overflow: hidden;
  float: right;
  margin-left: 20px;

  @media(max-width: 768px) {
    display: block;
    float: none;
    width: 70%;
    padding-top: 2rem;
  }
}

.vid-list-container:hover,
.vid-list-container:focus {
  overflow-y: auto;
}

ol#vid-list {
  margin: 0;
  padding: 0;
  background: #fff;
}

ol#vid-list li {
  list-style: none;
}

ol#vid-list li a {
  text-decoration: none;
  background-color: #fff;
  height: 55px;
  display: block;
  padding: 10px;
  position: relative;
}

ol#vid-list li a:hover {
  background-color: #eee;
}

.vid-thumb {
  float: left;
  margin-right: 8px;
}

.active-vid {
  background: #3a3a3a;
}

#vid-list .desc {
  color: #404040;
  font-size: 13px;
  margin-top: 5px;
}
.mobile-toggle-list, .mobile-list-close {
  display: none;
}

@media (max-width: 624px) {
  body {
    margin: 15px;
  }
  .caption {
    margin-top: 40px;
  }
  .vid-list-container {
    padding-bottom: 20px;
  }
  .mobile-toggle-list {
    display: block;
    margin-top: 10px;

    a {
      float: right;
      background: #f6b923;
      color: #fff;
      padding: 5px 10px;
    }
  }
  .vid-list-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    padding-top: 0;
    background: rgba(#000, .5);
    overflow-y: auto;
    display: none;

    &.open {
      display: block;
    }

    .mobile-list-close {
      color: #fff;
      padding: 15px;
      border: solid 1px #fff;
      position: relative;
      top: 20px;
      background: #000;
      display: block;
    }

    .vid-list {
      width: 75%;
      float: right;
    }
  }
}
</style>